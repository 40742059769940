import React, { FC, useState, useEffect } from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Verifica:FC = () => {
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [show, setShow] = useState(null);
  const [error, setError] = useState("");
  const [submit, setSubmitting] = useState<boolean>(false);
  const [fraine, setFraine] = useState("");

  useEffect(() => {
    fetch(
      "https://api.ambeedata.com/latest/by-lat-lng?lat=41.8979&lng=14.4898",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "x-api-key":
            "115a5cd82ce18d19b67618a46f925f3c97ebdbfbc4b396a68dacbf1c6760d38e",
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data) // body data type must match "Content-Type" header
      }
    )
      .then((data) => data.json())
      .then((response) => setFraine(response))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    if (submit) {
      fetch(
        `https://api.ambeedata.com/latest/by-lat-lng?lat=${lat}&lng=${long}`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "x-api-key":
              "115a5cd82ce18d19b67618a46f925f3c97ebdbfbc4b396a68dacbf1c6760d38e",
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          // body: JSON.stringify(data) // body data type must match "Content-Type" header
        }
      )
        .then((data) => data.json())
        .then((response) => setShow(response))
        .catch((error) => setError(error));
    }
    return setSubmitting(false);
  }, [submit]);

  return (
    <>
      <GatsbySeo
        title="Qualità dell'aria in tempo reale"
        description={`Consulta le stazioni più vicine a te per risultati accurati e precisi`}
        canonical="https://www.casafraine.com/qualita-dell-aria-nella-mia-citta"
        openGraph={{
          url: "https://www.casafraine.com/qualita-dell-aria-nella-mia-citta",
          title: "Qualità dell'aria in tempo reale",
          description: `Consulta le stazioni più vicine a te per risultati accurati e precisi`,
          images: [
            {
              url: "https://pbs.twimg.com/media/DuKqcY-X4AAAwz7.jpg",
              width: 800,
              height: 600,
              alt: "Consulta le stazioni più vicine a te per risultati accurati e precisi",
            },
            {
              url: "https://pbs.twimg.com/media/DuKqcY-X4AAAwz7.jpg",
              width: 900,
              height: 800,
              alt: "Consulta le stazioni più vicine a te per risultati accurati e precisi",
            },
            { url: "https://pbs.twimg.com/media/DuKqcY-X4AAAwz7.jpg" },
            { url: "https://pbs.twimg.com/media/DuKqcY-X4AAAwz7.jpg" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <section className="booking">
        {fraine && (
          <pre className="formatter">
            <code>{JSON.stringify(fraine)}</code>
          </pre>
        )}
        <center>
          <h1 style={{ fontSize: "2rem", marginBottom: "2rem" }}>
            Qual'è la qualità dell'aria dalle tue parti?
          </h1>
        </center>
        <form className="techie" onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="latitudine">Lat</label>
          <input
            type="number"
            value={lat}
            name="latitudine"
            onChange={(e) => setLat(e.target.value)}
            aria-label="latitudine"
          />
          <label htmlFor="longitudine">Long</label>
          <input
            type="number"
            value={long}
            name="longitudine"
            onChange={(e) => setLong(e.target.value)}
            aria-label="longitudine"
          />

          <button onClick={() => setSubmitting(true)}>verifica</button>
        </form>
        {submit && "hold on..."}
        {show && (
          <pre className="formatter">
            <code>{JSON.stringify(show)}</code>
          </pre>
        )}
        {error && (
          <pre className="formatter">
            <code>{JSON.stringify(error)}</code>
          </pre>
        )}
      </section>
    </>
  );
};

export default Verifica;
